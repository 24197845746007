<template>
  <div>
    <eden-page-header
      :title="'Services'"
      :subtitle="`${data[service]?.service_type_name}`"
    />
    <div v-if="!loading">
      <component
        :is="`${service}-form`"
        :service-type="data[service]"
        :service-type-id="serviceTypeId"
        action="edit"
      />
    </div> 
  </div>
</template>

<script>
import service from "@/requests/services/service";
import mealItem from "@/requests/services/meal";
import menu from "@/requests/services/meal/menu";

export default {
  name: "ServiceTypeEdit",
  components: {
    BeautyForm: () => import("@/components/Services/Beauty/BeautyForm"),
    CleaningForm: () => import("@/components/Services/Cleaning/CleaningForm"),
    LaundryForm: () =>
      import("@/components/Services/Laundry/LaundryTypes/LaundryTypeForm"),
    MealForm: () => import("@/components/Services/Meal/MealItems/MealItemForm"),
    MenuForm: () => import("@/components/Services/Meal/Menu/NewMenu/MenuForm"),
  },
  data() {
    return {
      loading: false,
      data: {
        beauty: {
          id: "",
          service: "Beauty",
          service_type_name: "",
          service_type_category: "single",
          limit: null,
          beauty_category: [],
        },
        cleaning: {
          id: "",
          service: "Cleaning",
          service_type_name: "",
          minimum_price: "",
          service_type_category: "single",
          cleaning_areas: [
            {
              slug: "",
              price: "",
            },
          ],
        },
        laundry: {
          id: "",
          service: "Laundry",
          service_type_name: "",
          service_type_category: "single",
        },
        meal: {
          service: "Meal",
          service_type_name: "",
          service_type_category: "single",
          meal_type: [],
          class_category: [],
          side_category: "",
          max_no_of_sides: 0,
          side_types: [],
          preset_combos: [],
          main_sides: null,
          protein_sides: null,
          sauce_sides: null,
          other_sides: null,
          ingredients: [],
          allergy: {
            seafood: "",
            shellfish: "",
            nut: "",
          },
          combo_image: null,
          image: null,
        },
        menu: {},
      },
    };
  },
  computed: {
    service() {
      return this.$route.params.service;
    },
    serviceTypeId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getServiceType();
  },
  methods: {
    getServiceType() {
      this.loading = true;
      console.log("rahhh");
      if (this.service === "meal") {
        mealItem
          .list(`?meal_id=${this.serviceTypeId}`)
          .then((res) => {
            const data = res.data.data[0];
            this.setMeal(data);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else if (this.service === "menu") {
        this.data.menu.service_type_name = "Edit Menu";
        menu
          .get(this.serviceTypeId)
          .then((res) => {
            console.log(res.data.data);
            this.data.menu = { ...res.data.data, ...this.data.menu };
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        service
          .serviceTypeGet(this.serviceTypeId)
          .then((response) => {
            if (response.data.status) {
              const data = response.data.data[0];
              switch (this.service) {
                case "beauty":
                  this.setBeauty(data);
                  break;
                case "cleaning":
                  this.setCleaning(data);
                  break;
                case "laundry":
                  this.setLaundry(data);
                  break;
                case "meal":
                  this.setMeal(data);
                  break;

                default:
                  break;
              }
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    setBeauty(data) {
      this.data.beauty.id = data.id;
      this.data.beauty.service_type_name = data.service_type;
      this.data.beauty.limit = data.limit;
      this.data.beauty.beauty_category = data.beauty_categories.map(
        (category) => {
          return {
            id: category.id,
            name: category.name,
            types: category.types.map((type) => {
              return {
                id: type.id,
                name: type.name,
                description: type.description,
                image: type.image,
                variations: type.variations.map((variation) => {
                  return {
                    id: variation.id,
                    name: variation.name,
                    image: variation.image,
                    price: variation.price,
                    time: variation.time ? variation.time / 60 : 0,
                  };
                }),
              };
            }),
            addons: category.addons.map((addon) => {
              return {
                name: addon.name,
                price: addon.price,
                time_to_serve: addon.time_to_serve
                  ? addon.time_to_serve / 60
                  : 0,
              };
            }),
          };
        },
      );
    },
    setCleaning(data) {
      this.data.cleaning.id = data.id;
      this.data.cleaning.service_type_name = data.service_type;
      this.data.cleaning.cleaning_areas = data.cleaning_areas;
      this.data.cleaning.minimum_price = data.customer_price;
    },
    setLaundry(data) {
      this.data.laundry.id = data.id;
      this.data.laundry.service_type_name = data.service_type;
    },
    setMeal(data) {
      console.log(data, "meal data")
      this.data.meal.id = data.id;
      this.data.meal.service_type_name = data.name;
      this.data.meal.service_name = data.service_name;
      this.data.meal.calorie = data.calorie;
      this.data.meal.meal_type = data.meal_type;
      this.data.meal.class_category = data.class_category?.split(",");

      const allergy = data.allergyTags;
      this.data.meal.allergy = {
        seafood: allergy ? allergy.seafood : "not-included",
        shellfish: allergy ? allergy.shellfish : "not-included",
        nut: allergy ? allergy.nut : "not-included",
      };
      this.data.meal.image = data.image_url;
      this.data.meal.combos = data.combos;
      this.data.meal.combo_image = data.combo_image_url;
      this.data.meal.ingredients = data.ingredients ?? [];
      this.data.meal.food_tags = data.food_tags ?? [];
      this.data.meal.chef_note = data.chef_note ?? "";

      if (data.meal_type === "main") {
        this.data.meal.main_with_sides = data.preset_combos
          ? !!data.preset_combos.length
          : false;
        const sideTypes = data.side_types;
        this.data.meal.side_types =
          sideTypes.filter((type) => !type.includes("soup")) || [];
        this.data.meal.preset_combos = data.preset_combos.length
          ? data.preset_combos.map((combo) => {
              return {
                ...combo,
                ingredients: combo.ingredients ?? [],
                food_tags: combo.food_tags ?? [],
                chef_note: combo.chef_note ?? "",
              };
            })
          : [];
      }

      if (this.data.meal.meal_type === "side") {
        this.data.meal.side_category = data.side_category;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
